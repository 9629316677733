exports.components = {
  "component---src-components-templates-campus-page-index-tsx": () => import("./../../../src/components/templates/CampusPage/index.tsx" /* webpackChunkName: "component---src-components-templates-campus-page-index-tsx" */),
  "component---src-components-templates-trainings-page-index-tsx": () => import("./../../../src/components/templates/trainingsPage/index.tsx" /* webpackChunkName: "component---src-components-templates-trainings-page-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-boost-index-tsx": () => import("./../../../src/pages/boost/index.tsx" /* webpackChunkName: "component---src-pages-boost-index-tsx" */),
  "component---src-pages-formations-tuition-fees-tsx": () => import("./../../../src/pages/formations/tuition-fees.tsx" /* webpackChunkName: "component---src-pages-formations-tuition-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-vodacom-digital-lab-index-tsx": () => import("./../../../src/pages/vodacom-digital-lab/index.tsx" /* webpackChunkName: "component---src-pages-vodacom-digital-lab-index-tsx" */)
}

